@media print {
  * {
    margin: 0;
    padding: 0;
  }
  * {
    page-break-before: auto !important;
    page-break-after: auto !important;
    break-before: auto !important;
    break-after: auto !important;
  }
  body * {
    visibility: hidden;
  }

  .receipt-content, .receipt-content * {
    visibility: visible;
  }

  .receipt-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* changes made here */
    width: 100%; 
  }

  .info-wrapper {
    display: flex;
    justify-content: flex-start; /* changes made here */
    width: 100%;
  }

  .sub-section {
    margin-top: 1em;
    padding: 10px;
    border-radius: 4px;
    background-color: #f7f7f7;
  }
  

  .receipt-content .info-section {
    text-align: left;
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    margin-bottom: 1em;
  }

  .receipt-content .info-section:first-child {
    margin-right: 2%;  /* Add right margin to the first section */
  }

  .receipt-content table {
    width: 100%;
    border-collapse: collapse;
  }

  .receipt-content th, .receipt-content td {
    border: 1px solid black;
    padding: 5px;
  }

  @page {
    size: A4;
    margin-left: 0mm;
    margin-right: 0mm;
    margin-top: 0mm;
    margin-bottom: 0mm;
    padding: auto;
  }
}


@media screen {
  * {
    margin: 0;
    padding: 0;
  }

  .info-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .receipt-content {
    display: flex;
    flex-direction: column;
    align-items: center; /* centers children horizontally */
  }

  .receipt-content .info-section {
      width: 100%;  /* Reduced width slightly to account for margins */
      padding: 0;
      box-sizing: border-box; /* ensures that padding doesn't increase the element's total width */
    }

    .sub-section {
      margin-top: 1em;
      padding: 10px;
      border-radius: 4px;
      background-color: #f7f7f7;
    }
    


  .receipt-content table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1em;
  }

  .receipt-content th, .receipt-content td {
    border: 1px solid black;
    padding: 5px;
  }
}
