/* PrintStyles.css */
.print-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: white;
    padding: 20px;
}

.print-content {
    border: 1px solid black;
    padding: 20px;
    background-color: white;
    text-align: center;
}

@media print {
    body * {
        visibility: hidden;
    }

    .print-wrapper,
    .print-content {
        border: none;
    }

    .print-wrapper * {
        visibility: visible;
    }

    .print-wrapper {
        position: absolute;
        left: 0;
        top: 0;
    }
}
